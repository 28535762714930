import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Prices } from '../../nordpool/Prices';
import FooterSml from '../footer/FooterSml';
import { NordPoolChart } from '../NordPoolChart';
import './HeroSection.css';

const HeroSection2 = () => {
  const prices = new Prices();
  const [monthlyPrices, setMonthlyPrices] = useState([]);
  const [nftOne, setNftOne] = useState();
  const [nftTwo, setNftTwo] = useState();
  const [nftThree, setNftThree] = useState();

  const largeViewHandler1 = () => {
    setNftOne(!nftOne);
  };
  const largeViewHandler2 = () => {
    setNftTwo(!nftTwo);
  };
  const largeViewHandler3 = () => {
    setNftThree(!nftThree);
  };

  useEffect(() => {
    fetchMonthlyPrices();
  }, []);

  const fetchMonthlyPrices = async () => {
    const results = await prices.monthly({ area: 'LV' });
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 12);

    setMonthlyPrices([
      ...results
        .map(item => {
          const date = new Date(item.date);
          const dateValue = `${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}`;

          const price = Math.round((item.value / 1000) * 100) / 100;
          if (date.getTime() >= startDate.getTime()) {
            return [dateValue, price];
          }

          return null;

          return [dateValue, price];
        })
        .filter(item => item),
    ]);
  };

  console.log('monthlyPrices', monthlyPrices);

  const avgValue =
    Math.round(
      (monthlyPrices.reduce((a, b) => a + b[1], 0) / monthlyPrices.length) *
        1000
    ) / 1000;
  console.log('avgValue', avgValue);
  const nft1Return =
    Math.round(((70 * (avgValue - 0.025) * 0.75) / 100) * 10000) / 100;
  const nft2Return =
    Math.round(((350 * (avgValue - 0.025) * 0.8) / 500) * 10000) / 100;
  const nft3Return =
    Math.round(((1750 * (avgValue - 0.025) * 0.85) / 2500) * 10000) / 100;

  return (
    <Container fluid className='herosection__container'>
      <Container>
        <Row className='hero_col_wrap'>
          <Col sm={12} md={6} xl={6} className='hero__cols'>
            <NordPoolChart />
          </Col>
          <Col sm={12} xl={6}>
            <Row>
              <h3 className='hero__h3'>
                {' '}
                Available ongrid Wp Power for <br />
                <span> Minting 500 / 1,000</span>
              </h3>
              <Col sm={6} md={4} className='mobi__margin'>
                {' '}
                <div className='hero__card1'>
                  <h3>
                    {' '}
                    Yearly Return <br /> {nft1Return} %{' '}
                  </h3>
                  <p>
                    {/* {' '}
                    NFT 1{' '} */}
                  </p>
                  <p> 70 Wp</p>
                  <p> 100 USDC</p>
                  <p> 75% reward of production</p>
                  <div className='hero__counter'>
                    <div className='counter__dec'> - </div>{' '}
                    <div className='counter__num'> 1</div>{' '}
                    <div className='counter__inc'> + </div>
                  </div>
                  <button className='hero__mint__btn'> Mint </button>
                </div>
              </Col>
              <Col sm={6} md={4} className='mobi__margin'>
                {' '}
                <div className='hero__card2'>
                  <h3>
                    {' '}
                    Yearly Return <br /> {nft2Return} %{' '}
                  </h3>
                  <p>
                    {/* {' '}
                    NFT 2{' '} */}
                  </p>
                  <p> 250 Wp</p>
                  <p> 500 USDC</p>
                  <p> 80% reward of production</p>
                  <div className='hero__counter'>
                    <div className='counter__dec'> - </div>{' '}
                    <div className='counter__num'> 1</div>{' '}
                    <div className='counter__inc'> + </div>{' '}
                  </div>
                  <button className='hero__mint__btn'> Mint </button>
                </div>
              </Col>
              <Col sm={6} md={4} className='mobi__margin'>
                {' '}
                <div className='hero__card3'>
                  <h3>
                    {' '}
                    Yearly Return <br /> {nft3Return} %{' '}
                  </h3>
                  <p>
                    {/* {' '}
                    NFT 3 */}
                  </p>
                  <p> 1750 Wp</p>
                  <p> 2500 USDC</p>
                  <p> 85% reward of production</p>
                  <div className='hero__counter'>
                    <div className='counter__dec'> - </div>{' '}
                    <div className='counter__num'> 1</div>{' '}
                    <div className='counter__inc'> + </div>{' '}
                  </div>
                  <button className='hero__mint__btn'> Mint </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className='hero_col_wrap'>
          <Col sm={12} md={6} xl={6} className='hero__cols'>
            <NordPoolChart />
          </Col>
          <Col sm={12} xl={6}>
            <Row>
              <h3 className='hero__h3'>
                {' '}
                Available off grid Wp Power for <br />
                <span> Minting 500 / 1,000</span>
              </h3>
              <Col sm={6} md={4} className='mobi__margin'>
                {' '}
                <div className='hero__card off-grid'>
                  <h3>
                    {' '}
                    Yearly Return <br /> {nft1Return} %{' '}
                  </h3>
                  <p>
                    {/* {' '}
                    NFT 1{' '} */}
                  </p>
                  <p> 70 Wp</p>
                  <p> 100 USDC</p>
                  <p> 75% reward of production</p>
                  <div className='hero__counter'>
                    <div className='counter__dec'> - </div>{' '}
                    <div className='counter__num'> 1</div>{' '}
                    <div className='counter__inc'> + </div>
                  </div>
                  <button className='hero__mint__btn'> Mint </button>
                </div>
              </Col>
              <Col sm={6} md={4} className='mobi__margin'>
                {' '}
                <div className='hero__card off-grid'>
                  <h3>
                    {' '}
                    Yearly Return <br /> {nft2Return} %{' '}
                  </h3>
                  <p>
                    {/* {' '}
                    NFT 2{' '} */}
                  </p>
                  <p> 250 Wp</p>
                  <p> 500 USDC</p>
                  <p> 80% reward of production</p>
                  <div className='hero__counter'>
                    <div className='counter__dec'> - </div>{' '}
                    <div className='counter__num'> 1</div>{' '}
                    <div className='counter__inc'> + </div>{' '}
                  </div>
                  <button className='hero__mint__btn'> Mint </button>
                </div>
              </Col>
              <Col sm={6} md={4} className='mobi__margin'>
                {' '}
                <div className='hero__card off-grid'>
                  <h3>
                    {' '}
                    Yearly Return <br /> {nft3Return} %{' '}
                  </h3>
                  <p>
                    {/* {' '}
                    NFT 3 */}
                  </p>
                  <p> 1750 Wp</p>
                  <p> 2500 USDC</p>
                  <p> 85% reward of production</p>
                  <div className='hero__counter'>
                    <div className='counter__dec'> - </div>{' '}
                    <div className='counter__num'> 1</div>{' '}
                    <div className='counter__inc'> + </div>{' '}
                  </div>
                  <button className='hero__mint__btn'> Mint </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className=' hero_col_wrap text-center'>
          <Col sm={12} xl={2} className='text-center'></Col>
          <Col sm={12} xl={8} className='text-center'>
            {' '}
            <Row>
              <Col sm={12} md={12}>
                <div className='hero__box2'>
                  <h3>
                    Your Total 200 kWp <span> on Grid</span>
                  </h3>
                </div>
                <div className='hero__box2'>
                  <h3>
                    Your Total 200 kWp <span> off Grid</span>
                  </h3>
                </div>
              </Col>
            </Row>
            <Row className='bot__row1'>
              <Col sm={12} md={12} lg={6}>
                <h3>
                  Pending Rewards - <span> 200 USDC</span>
                </h3>
              </Col>
              <Col sm={12} md={12} lg={3}>
                <button className='hero2__std__btn'> CLAIM </button>
              </Col>
              <Col sm={12} md={12} lg={3}>
                <button className='hero2__std__btn'> COMPOUND </button>
              </Col>
            </Row>
            <Row className='bot__row2'>
              <Col md={6}>
                <h3>
                  Upgreadeable NFT's - <span> 500kWp </span>
                </h3>
              </Col>
              <Col md={3}>
                <div>
                  <button className='hero2__std__btn'> UPGRADE </button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <FooterSml />
      </Container>
    </Container>
  );
};

export default HeroSection2;
