import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import { Col, Offcanvas, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import LOGO from '../../assets/Images/logo-horiz.png';
import { HashLink } from 'react-router-hash-link';
import MyButton from '../UI/Button';
import './mainnav.css';

const MainNav = props => {
  const [expand, setExpanded] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const topMenuHandler = () => {
    const scroll = document.body.scrollTo();
    if (window.pageYOffset < 250) {
      setShowMenu(true);
      console.log('call');
    } else {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', topMenuHandler);
    return () => window.removeEventListener('scroll', topMenuHandler);
  }, []);

  return (
    <Container
      className='main__container'
      // className={!showMenu ? 'sticky-menu active-height' : 'menu__nav'}
      fluid
    >
      <Container>
        <Row>
          <Col className='menu__nav'>
            <div>
              {' '}
              <a href='#'>
                <img src={LOGO} alt='' className='solarlogo' />
              </a>
            </div>

            <div>
              <a href='#' className='connect__btn'>
                Connect Wallet
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
    // <Container fluid className={!showMenu && 'sticky-menu active-height'}>
    //   {['sm'].map(expand => (
    //     <Navbar key={expand} expand={expand} className='np'>
    //       <Container fluid className='menu__nav'>
    //         <Navbar.Brand href='#' className='flex-grow-1'>
    //           <img src={LOGO} alt=' logo' />
    //         </Navbar.Brand>
    //         <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
    //         <Navbar.Offcanvas
    //           id={`offcanvasNavbar-expand-${expand}`}
    //           aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
    //           placement='end'
    //         >
    //           <Offcanvas.Header closeButton>
    //             <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
    //               Offcanvas
    //             </Offcanvas.Title>
    //           </Offcanvas.Header>
    //           <Offcanvas.Body>
    //             <Nav className=' flex-grow-1 pe-3 main__nav__links'>
    //               <HashLink
    //                 smooth={true}
    //                 to='#home'
    //                 onClick={() => setExpanded(!expand)}
    //               >
    //                 Home
    //               </HashLink>
    //               <HashLink smooth={true} to='#aboutus'>
    //                 About Us
    //               </HashLink>
    //               <HashLink smooth={true} to='#roadmap'>
    //                 Roadmap
    //               </HashLink>
    //               <HashLink smooth={true} to='#our-team'>
    //                 Our Team
    //               </HashLink>
    //               <HashLink smooth={true} to='#why-us'>
    //                 Why Us
    //               </HashLink>
    //               <HashLink smooth={true} to='#contact-us'>
    //                 Contact Us
    //               </HashLink>
    //             </Nav>
    //             <Navbar.Brand href='#' className='flex-grow-2  pt-4'>
    //               <MyButton title='Buy Now' />
    //             </Navbar.Brand>
    //           </Offcanvas.Body>
    //         </Navbar.Offcanvas>
    //       </Container>
    //     </Navbar>
    //   ))}
    //   {/* <header id='header'>
    //     <div id='header-fixed-height'></div>
    //     <div className='sticky-header menu-area'>
    //       <div className='container custom-container'>
    //         <div className='row'>
    //           <div className='col-12'>
    //             <div className='mobile-nav-toggler'>
    //               <i className='fas fa-bars'></i>
    //             </div>
    //             <div className='menu-wrap'>
    //               <nav className='menu-nav'>
    //                 <div className='logo'>
    //                   <a href='index.html'>
    //                     <img src='assets/img/logo/logo.png' alt='' />
    //                   </a>
    //                 </div>
    //                 <div className='navbar-wrap main-menu d-none d-lg-flex'>
    //                   <ul className='navigation'>
    //                     <li className='active menu-item-has-children'>
    //                       <a href='#header' className='section-link'>
    //                         Home
    //                       </a>
    //                       <ul className='sub-menu'>
    //                         <li className='active'>
    //                           <a href='index.html'>Home One</a>
    //                         </li>
    //                         <li>
    //                           <a href='index-2.html'>Home Two</a>
    //                         </li>
    //                       </ul>
    //                     </li>
    //                     <li>
    //                       <a href='#about' className='section-link'>
    //                         About us
    //                       </a>
    //                     </li>
    //                     <li>
    //                       <a href='#sales' className='section-link'>
    //                         Sales
    //                       </a>
    //                     </li>
    //                     <li>
    //                       <a href='#roadmap' className='section-link'>
    //                         Roadmap
    //                       </a>
    //                     </li>
    //                     <li className='menu-item-has-children'>
    //                       <a href='blog.html'>Blog</a>
    //                       <ul className='sub-menu'>
    //                         <li>
    //                           <a href='blog.html'>Our Blog</a>
    //                         </li>
    //                         <li>
    //                           <a href='blog-details.html'>Blog Details</a>
    //                         </li>
    //                       </ul>
    //                     </li>
    //                     <li>
    //                       <a href='#contact' className='section-link'>
    //                         Contact us
    //                       </a>
    //                     </li>
    //                   </ul>
    //                 </div>
    //                 <div className='header-action d-none d-md-block'>
    //                   <ul>
    //                     <li className='header-lang'>
    //                       <span className='selected-lang'>ENG</span>
    //                       <ul className='lang-list'>
    //                         <li>
    //                           <a href='#'>IND</a>
    //                         </li>
    //                         <li>
    //                           <a href='#'>BNG</a>
    //                         </li>
    //                         <li>
    //                           <a href='#'>TUR</a>
    //                         </li>
    //                         <li>
    //                           <a href='#'>CIN</a>
    //                         </li>
    //                       </ul>
    //                     </li>
    //                     <li className='header-btn'>
    //                       <a href='#' className='btn'>
    //                         Buy Now
    //                       </a>
    //                     </li>
    //                   </ul>
    //                 </div>
    //               </nav>
    //             </div>

    //             <div className='mobile-menu'>
    //               <nav className='menu-box'>
    //                 <div className='close-btn'>
    //                   <i className='fas fa-times'></i>
    //                 </div>
    //                 <div className='nav-logo'>
    //                   <a href='index.html'>
    //                     <img src='assets/img/logo/logo.png' alt='' title='' />
    //                   </a>
    //                 </div>
    //                 <div className='menu-outer'></div>
    //                 <div className='social-links'>
    //                   <ul className='clearfix'>
    //                     <li>
    //                       <a href='#'>
    //                         <i className='fab fa-facebook-f'></i>
    //                       </a>
    //                     </li>
    //                     <li>
    //                       <a href='#'>
    //                         <i className='fab fa-twitter'></i>
    //                       </a>
    //                     </li>
    //                     <li>
    //                       <a href='#'>
    //                         <i className='fab fa-instagram'></i>
    //                       </a>
    //                     </li>
    //                     <li>
    //                       <a href='#'>
    //                         <i className='fab fa-linkedin-in'></i>
    //                       </a>
    //                     </li>
    //                     <li>
    //                       <a href='#'>
    //                         <i className='fab fa-youtube'></i>
    //                       </a>
    //                     </li>
    //                   </ul>
    //                 </div>
    //               </nav>
    //             </div>
    //             <div className='menu-backdrop'></div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </header> */}
    // </Container>
  );
};

export default MainNav;
