import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FaCopyright } from 'react-icons/fa';

const FooterSml = () => {
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <p
            style={{
              fontSize: '14px',
              color: '#fff',
              letterSpacing: '1px ',
              paddingTop: '50px',
            }}
          >
            <FaCopyright style={{ marginBottom: '2px', marginRight: '5px' }} />{' '}
            Copyright 2022 | The Solar Share
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterSml;
