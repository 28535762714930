'use strict'
// const baseUrl = 'http://www.nordpoolspot.com/api/'
// const baseUrl = 'http://app.ticketmount.local/api/nordpool';
const baseUrl = 'https://nordpool.thewebsters.org/index.php';

const config = {
  baseUrl: baseUrl,
  // priceUrlHourly: baseUrl + '/path=marketdata/page/10',
  // priceUrlDaily: baseUrl + '/path=marketdata/page/11',
  // priceUrlWeekly: baseUrl + '/path=marketdata/page/12',
  // priceUrlMonthly: baseUrl + '/path=marketdata/page/13',
  // priceUrlYearly: baseUrl + '/path=marketdata/page/14',
  priceUrlHourly: baseUrl + '?path=10',
  priceUrlDaily: baseUrl + '?path=11',
  priceUrlWeekly: baseUrl + '?path=12',
  priceUrlMonthly: baseUrl + '?path=13',
  priceUrlYearly: baseUrl + '?path=14',
  // volumeUrl: baseUrl + '/marketdata/page/',
  // capacityUrl: baseUrl + '/marketdata/page/',
  // flowUrl: baseUrl + '/marketdata/page/',
  timezone: 'Europe/Oslo'
}

export { config };

