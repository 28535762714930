import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef, useState } from 'react';
import { Prices } from "../../nordpool/Prices";

export const NordPoolChart = () => {
  const [graphPrices, setGraphPrices] = useState([]);
  const timer = useRef(null);
  const prices = new Prices();

  useEffect(() => {
    dailyPrices();
  }, []);

  const hourlyPrices = async () => {
    const results = await prices.daily({area:'LV'})
    setGraphPrices([...results.map(item => {
      const date = new Date(item.date);
      const dateValue = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      
      const hour = date.getHours().toString().padStart(2, '0').concat(':00');
      const price = Math.round(item.value )/1000;
      return [hour, price];
    })]);
  }

  const dailyPrices = async () => {
    const results = await prices.daily({area:'LV'})
    setGraphPrices([...results.map(item => {
      const date = new Date(item.date);
      const dateValue = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      
      const price = Math.round((item.value/1000) * 100) / 100;
      return [dateValue, price];
    })]);
  }
  
  const avgValue = Math.round((graphPrices.reduce((a, b) => a + b[1], 0) / graphPrices.length) * 100) / 100;

  const options = {
    chart: {
      type: 'spline',
      backgroundColor: 'transparent',
      // color: '#fff',
      
    },
    title: {
        text: '<b>Prices</b>',
        style: {
            color: '#fff',
        }
    },
    xAxis: {
      // type: "datetime",
        title: {
            enabled: true,
            text: 'Date <br /> <b>AVG: ' + avgValue + ' EUR/kWh°</b>',
            style: {
                color: '#fff'
            }
        },
        labels: {
            // format: '{value:%d-%m-%Y}',
            style: {
                color: '#fff'
            }
        },
        maxPadding: 0.05,
        showLastLabel: true,
        categories: graphPrices.map(item => item[0]).reverse()
    },
    yAxis: {
        title: {
            text: 'Price',
            style: {
                color: '#fff'
            }
        },
        labels: {
            format: '{value} EUR/kWh°',
            style: {
                color: '#fff'
            }
        },
        lineWidth: 2,
        plotLines: [{
          value: graphPrices.reduce((a, b) => a + b[1], 0) / graphPrices.length,
          width: 3,
          color: 'rgba(255, 0, 0, 0.5)',
          dashStyle: 'solid'
        }],
    },
    legend: {
        enabled: false
    },
    tooltip: {
        headerFormat: '<b>Daily Price at {point.x:%d-%m-%Y} </b><br/><br/>',
        pointFormat: '{point.y} EUR/kWh°'
    },
    plotOptions: {
        spline: {
            marker: {
                enable: false
            }
        }
    },
    series: [
      {
        name: 'Prices',
        data: [1, 2, 1, 4, 3, 6]
      }
    ]
  };
  
  return (
    <div style={{ width: '100%', height: '100%', marginTop: 120, background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), rgba(255, 255, 255, 0.2)', padding: 10, borderRadius: 10 }}>
      <HighchartsReact highcharts={Highcharts} options={{...options, series: [{ name: 'Prices', data: graphPrices.map(item => item[1]).reverse(), color: '#fff'}]}} />
    </div>
  );
}
