import React from 'react';
import About from '../components/about/About';
import Contact from '../components/contactus/Contact';
import Footer from '../components/footer/Footer';
import HeroSection from '../components/herosection/HeroSection';
import HeroSection2 from '../components/herosection/HeroSection2';
import OurTeam from '../components/ourteam/OurTeam';
import RoadMap from '../components/roadmap/RoadMap';
import Sales from '../components/sales/Sales';
import WhyUs from '../components/whychooseus/WhyUs';

const Home = () => {
  return (
    <div>
      {/* <MainNav /> */}
      <div id='home'>
        {/* <HeroSection /> */}
        <HeroSection2 />
      </div>
      <div id='aboutus'>{/* <About /> */}</div>
      <div id='whyus'>{/* <WhyUs /> */}</div>
      <div id='roadmap'>{/* <RoadMap /> */}</div>
      <div id='ourteam'>{/* <OurTeam /> */}</div>
      <div id='contact'>{/* <Contact /> */}</div>
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
